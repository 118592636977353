import React from 'react';
import { useTranslation } from 'react-i18next';
import useTransactionStream from '../../hooks/use-transaction-stream';
import LocationsCount from '../../modules/programs/components/LocationsCount';
import { ProgramType } from '../../store/programs/programs-reducer';
import { useAppSelector } from '../../store/hooks';

export default function ProgramOptionInfo({
  id,
  type,
}: {
  id: string;
  type: ProgramType | undefined;
}) {
  const { t } = useTranslation('programs');
  const isLive = useAppSelector(state => state.live);
  const { hasAccountTransactionStream } = useTransactionStream();

  if (hasAccountTransactionStream) return null;

  if (isLive) {
    return <LocationsCount type={type} programId={id} />;
  }

  return (
    <>
      {type === 'transaction-stream'
        ? t('programType.transaction-stream.label')
        : t('programType.transaction-select.label')}
    </>
  );
}
