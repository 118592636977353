import React, { useEffect } from 'react';
import { Col, Row, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import { ProgramType } from '@fidel.uk/types/lib/program';
import Form from '../../../../components/form';
import { ProgramDropdown } from '../../../../components/dropdowns';
import { Offer } from '../../../../store/offers/offers-model';
import {
  selectCountryProgramsByType,
  selectProgramsAsList,
} from '../../../../store/programs/programs-selectors';
import { useAppSelector } from '../../../../store/hooks';

export default function ProgramSelection({
  uniqueOffer,
}: {
  uniqueOffer: Offer;
}) {
  const { t } = useTranslation('offers');

  const { countryCode, programsLinkStatus, transactionSource } = uniqueOffer;

  const {
    control,
    formState: { errors, isDirty },
    setValue,
  } = useFormContext();

  const programsByCountry = useAppSelector(selectCountryProgramsByType)(
    countryCode,
  );

  const isOaas = transactionSource === OfferTransactionSource.OAAS;

  const distroOnlyPrograms = useAppSelector(selectProgramsAsList)(
    ProgramType.TRANSACTION_DISTRO_ONLY,
  );

  const programsAddedToOffer = programsLinkStatus?.map(({ id }) => id) || [];
  const availablePrograms = (
    isOaas ? distroOnlyPrograms : programsByCountry
  ).filter(({ id }) => !programsAddedToOffer.includes(id));

  const availableProgramsIds = availablePrograms.map(({ id }) => id);

  useEffect(() => {
    if (!isDirty)
      setValue(
        'programIds',
        availableProgramsIds.length === 1 ? availableProgramsIds : [],
      );
  }, [availableProgramsIds, setValue, isDirty]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ paddingTop: 24, marginBottom: 16 }}>
            <Col span={24}>
              <Typography.Paragraph>
                {t('marketplace.addOffer.steps.programSelection.title')}
              </Typography.Paragraph>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.ItemController
                errors={errors}
                label={t('fields.programs.label')}
                controller={{
                  name: 'programIds',
                  render: ({ field: { onChange, value } }) => (
                    <ProgramDropdown
                      formItemProps={{
                        style: { marginBottom: 0 },
                      }}
                      selector={() => availablePrograms}
                      onChange={values => {
                        onChange(
                          availableProgramsIds.filter(id =>
                            values.includes(id),
                          ),
                        );
                      }}
                      value={value}
                      multiple
                      placeholder="transactions.label.selectPrograms"
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />
    </>
  );
}
