import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Radio } from 'antd';

import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import Form from '../../../components/form';
import Avatar from '../../../components/avatar';
import { colors } from '../../../theme';
import { OfferTransactionSourceRadio } from '../create-update-offer/styled/selectOfferTransactionSource';
import { BoldText } from '../../../components/styled/light-bold-text';

const SelectOfferTransactionSource = () => {
  const { t } = useTranslation('offers');

  const { control } = useFormContext();

  return (
    <Form.ItemController
      controller={{
        name: 'offerTransactionSource',
        render: ({ field }) => (
          <Radio.Group
            {...field}
            defaultValue="select"
            style={{ width: '100%' }}
          >
            {Object.values(OfferTransactionSource).map(type => (
              <OfferTransactionSourceRadio
                key={type}
                value={type}
                $active={type === field.value}
              >
                <header>
                  {t(`offerTransactionSource.${type}.label`)}
                  <Avatar
                    size={24}
                    textFormat="all"
                    shape="circle"
                    color={{
                      background:
                        type === 'select'
                          ? colors.product.selectTransaction
                          : colors.product.transactionStream,
                    }}
                  >
                    {t(`offerTransactionSource.${type}.label`)}
                  </Avatar>
                </header>
                <p>
                  <Trans
                    i18nKey={`offerTransactionSource.${type}.description`}
                    ns="offers"
                    components={{
                      bold: <BoldText />,
                    }}
                    load
                  />
                </p>
              </OfferTransactionSourceRadio>
            ))}
          </Radio.Group>
        ),
        rules: { required: true },
        control,
      }}
    />
  );
};

export default SelectOfferTransactionSource;
