import React, { useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OfferTransactionSource } from '@fidel.uk/types/lib/offer';
import { FilterName } from '../components/FilterDropdown';
import { selectProgramsAsList } from '../../../store/programs/programs-selectors';
import { supportedCountries } from '../../../utils/countries';
import { CountryCode } from '../../../types';
import useDebounce from '../../../hooks/use-debounce';
import StatusTag, {
  Status,
} from '../../../modules/offers/components/StatusTag';
import {
  useAccountUniqueBrands,
  useBrands,
} from '../../../modules/brands/hooks/useBrands';
import { useAppSelector } from '../../../store/hooks';
import { Filter, FiltersProps } from '..';

export default function useFiltersConfig({
  defaultFilters,
  moreFilters = [],
}: FiltersProps) {
  const { t } = useTranslation();
  const { t: offersT } = useTranslation('offers');
  const [uniqueBrandSearchTerm, setUniqueBrandSearchTerm] = useState('');
  const debouncedUniqueBrandSearchTerm = useDebounce(
    uniqueBrandSearchTerm,
    500,
  );
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const debouncedBrandSearchTerm = useDebounce(brandSearchTerm, 500);
  const programs = useAppSelector(selectProgramsAsList)('transaction-select');
  const defaultFiltersKeys = useRef(defaultFilters);
  const moreFiltersKeys = useRef(moreFilters);
  const {
    data: uniqueBrands,
    isFetching: fetchingUniqueBrands,
    hasNextPage: hasNextUniqueBrands,
    fetchNextPage: fetchNextUniqueBrands,
  } = useAccountUniqueBrands({ searchTerm: debouncedUniqueBrandSearchTerm });
  const {
    data: brands,
    isFetching: fetchingBrands,
    hasNextPage: hasNextBrands,
    fetchNextPage: fetchNextBrands,
  } = useBrands({
    searchTerm: debouncedBrandSearchTerm,
  });
  const {
    marketplaceCountries,
    loading,
    contentProviders,
    accountContentProviderIds,
  } = useAppSelector(
    state => ({
      marketplaceCountries: state.account.marketplace.countryCodes,
      loading: state.programs.loading || state.offers.loading,
      contentProviders: state.offers.contentProviders,
      accountContentProviderIds: state.account.marketplace.suppliers,
    }),
    shallowEqual,
  );

  const configs = useMemo(
    (): { [name in FilterName]: Filter } => ({
      activation: {
        name: 'activation',
        options: [
          { id: 'true', name: t('required') },
          { id: 'false', name: t('notRequired') },
        ],
      },
      approval: {
        name: 'approval',
        options: [
          { id: 'true', name: t('required') },
          { id: 'false', name: t('notRequired') },
        ],
      },
      brandId: {
        name: 'brandId',
        options:
          brands?.pages.flatMap(page =>
            page.items.map(({ id, name }) => ({ id, name })),
          ) || [],
        multiple: true,
        search: {
          setSearchTerm: setBrandSearchTerm,
          searchTerm: debouncedBrandSearchTerm,
          onLoadMore: hasNextBrands ? fetchNextBrands : undefined,
        },
      },
      uniqueBrandId: {
        name: 'brandId',
        options:
          uniqueBrands?.pages.flatMap(page =>
            page.items
              .filter(
                ({ uniqueBrandId, uniqueBrandName }) =>
                  uniqueBrandId && uniqueBrandName,
              )
              .map(({ uniqueBrandId, uniqueBrandName }) => ({
                id: uniqueBrandId,
                name: uniqueBrandName,
              })),
          ) || [],
        multiple: true,
        search: {
          setSearchTerm: setUniqueBrandSearchTerm,
          searchTerm: debouncedUniqueBrandSearchTerm,
          onLoadMore: hasNextUniqueBrands ? fetchNextUniqueBrands : undefined,
        },
      },
      channel: {
        name: 'channel',
        options: [
          { id: 'online', name: t('filters.channel.options.online') },
          { id: 'in-store', name: t('filters.channel.options.inStore') },
        ],
      },
      channelWithNotSpecified: {
        name: 'channel',
        options: [
          { id: 'online', name: t('filters.channel.options.online') },
          { id: 'in-store', name: t('filters.channel.options.inStore') },
          {
            id: 'not-specified',
            name: t('filters.channel.options.notSpecified'),
          },
        ],
      },
      contentProviderId: {
        name: 'contentProviderId',
        options: contentProviders
          .filter(({ id }) => accountContentProviderIds.includes(id))
          .map(({ name, id }) => ({
            name,
            id,
          })),
        multiple: true,
      },
      countryCode: {
        name: 'countryCode',
        options: supportedCountries.map(({ name, code }) => ({
          name,
          id: code,
        })),
        multiple: true,
      },
      marketplaceCountries: {
        name: 'countryCode',
        options: supportedCountries
          .filter(({ code }) =>
            marketplaceCountries.includes(code as CountryCode),
          )
          .map(({ name, code }) => ({
            name,
            id: code,
          })),
        multiple: true,
      },
      customerRecency: {
        name: 'customerRecency',
        options: [
          { id: 'true', name: t('yes') },
          { id: 'false', name: t('no') },
        ],
      },
      endDate: {
        name: 'endDate',
        options: [
          { id: 'true', name: t('yes') },
          { id: 'false', name: t('no') },
        ],
      },
      network: {
        name: 'network',
        options: [
          { id: 'amex', name: t('schemes.amex') },
          { id: 'mastercard', name: t('schemes.mastercard') },
          { id: 'visa', name: t('schemes.visa') },
        ],
        multiple: true,
      },
      programId: {
        name: 'programId',
        options: programs,
        multiple: true,
      },
      availableProgramId: {
        name: 'availableProgramId',
        options: programs,
        multiple: true,
      },
      returnPeriod: {
        name: 'returnPeriod',
        options: [
          { id: 'true', name: t('yes') },
          { id: 'false', name: t('no') },
        ],
      },
      offerStatus: {
        name: 'offerStatus',
        multiple: true,
        options: Array<Status>(
          'processing_configuration',
          'pending',
          'processing_approval',
        ).map(status => ({
          id: status,
          name: <StatusTag status={status} />,
        })),
      },
      transactionSource: {
        name: 'transactionSource',
        options: Object.values(OfferTransactionSource).map(txnSource => ({
          id: txnSource,
          name: offersT(`offerTransactionSource.${txnSource}.shortTitle`),
        })),
        multiple: false,
      },
    }),
    [
      accountContentProviderIds,
      brands?.pages,
      debouncedBrandSearchTerm,
      debouncedUniqueBrandSearchTerm,
      fetchNextBrands,
      fetchNextUniqueBrands,
      hasNextBrands,
      hasNextUniqueBrands,
      marketplaceCountries,
      programs,
      contentProviders,
      t,
      offersT,
      uniqueBrands?.pages,
    ],
  );

  const moreFiltersResult = useRef(
    moreFiltersKeys.current.map(filter => configs[filter]),
  );

  return {
    defaultFilters: useMemo(
      () => defaultFiltersKeys.current.map(filter => configs[filter]),
      [configs, defaultFiltersKeys],
    ),
    moreFilters: moreFiltersResult.current,
    loading: loading || fetchingUniqueBrands || fetchingBrands,
  };
}
