import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { transformOfferToDetailTitle } from '../../utils';
import { Offer } from '../../../../store/offers/offers-model';
import DetailTitle from '../../../../components/detail/components/DetailTitle';
import { useAppSelector } from '../../../../store/hooks';
import { Brand } from '../../../../store/brands/brands-model';
import MidsInput from '../offer-form/components/MidsInput';
import { Label } from '../styled/add-locations-form';

export default function MidsForm({ offer }: { offer: Partial<Offer> }) {
  const { brandId } = offer;

  const { brands } = useAppSelector(
    ({ programs, offers, locations, ...state }) => ({
      brands: state.brands.brands,
    }),
  );

  const brand = useMemo<Brand>(
    () => brands.find(({ id }) => id === brandId) ?? ({} as Brand),
    [brandId, brands],
  );

  const { t } = useTranslation('offers');

  return (
    <>
      <DetailTitle
        {...transformOfferToDetailTitle({
          ...offer,
          brandLogoURL: brand.logoURL,
          brandName: brand.name,
        })}
      />

      <div style={{ width: 'flex', marginTop: '1rem' }}>
        <Label>{t('createUpdate.mids.label')}</Label>
        <MidsInput isDisabled={!!offer.id} />
      </div>
    </>
  );
}
