import { RightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { OfferTransactionSource as OfferTransactionSourceEnum } from '@fidel.uk/types/lib/offer';
import DrawerForm from '../../../../components/drawer-form';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../../components/drawer-transition';
import { Title } from '../styled';
import SelectOfferTransactionSource from '../../components/SelectOfferTransactionSource';

export interface OfferTransactionSourceProps extends DrawerVisibleProps {
  onClose: () => void;
  setOfferTransactionSource: (
    offerTransactionSource: OfferTransactionSourceEnum,
  ) => void;
}

function OfferTransactionSource({
  onClose,
  visible,
  setOfferTransactionSource,
}: OfferTransactionSourceProps) {
  const { t } = useTranslation('offers');

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      offerTransactionSource: OfferTransactionSourceEnum.SELECT,
    },
  });

  const { handleSubmit } = form;

  const header = () => (
    <Title>
      <span>{t(`createUpdate.title.create`)}</span>
    </Title>
  );

  function confirmText() {
    return (
      <Row align="middle">
        <span>{t(`createUpdate.confirmText.next`)}</span>
        <RightOutlined style={{ fontSize: '8px', marginLeft: '14px' }} />
      </Row>
    );
  }

  function onSubmit({ offerTransactionSource }: any) {
    setOfferTransactionSource(offerTransactionSource);
  }

  return (
    <DrawerForm
      title={header()}
      confirmText={confirmText()}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      visible={visible}
      processing={false}
      push={{ distance: 8 }}
    >
      <FormProvider {...form}>
        <Title>{t('offerTransactionSource.subTitle')}</Title>
        <SelectOfferTransactionSource />
      </FormProvider>
    </DrawerForm>
  );
}

export default drawerTransition(OfferTransactionSource);
