import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { State } from '..';
import { CountryCode } from '../../types';
import { Program, ProgramType } from './programs-reducer';

export function filterProgramByType(program: Program, type?: ProgramType) {
  if (type === 'transaction-select') {
    return !program.type || program.type === type;
  }

  if (type === 'transaction-stream' || type === 'transaction-distro-only') {
    return program.type === type;
  }

  return true;
}

export const selectProgramsAsList = createSelector(
  (state: State) => state.programs.programs,
  programs =>
    memoize((type?: ProgramType) =>
      Object.values(programs).filter(program =>
        filterProgramByType(program, type),
      ),
    ),
);

export const selectCountryProgramsByType = createSelector(
  (state: State) => state.programs.programsByCountry,
  programs =>
    memoize((country?: CountryCode, type: ProgramType = 'transaction-select') =>
      country
        ? programs[country].filter(program =>
            filterProgramByType(program, type),
          )
        : [],
    ),
);
