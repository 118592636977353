import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { TFunction, useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { api } from '../../../config';
import { getLocationsCount } from '../../../store/locations/locations-actions';
import { DOUBLE_MINUS } from '../../../utils/special-character';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ProgramType } from '../../../store/programs/programs-reducer';

function getCountDisplay(
  t: TFunction<'programs'>,
  count?: number,
  type?: ProgramType,
) {
  if (count === undefined || type === 'transaction-distro-only')
    return DOUBLE_MINUS;
  if (count < api.MAX_LOCATIONS_COUNT) return t('locationsCount', { count });

  return t('locationsCount_plus', {
    count: api.MAX_LOCATIONS_COUNT,
  });
}

const LocationsCount = ({
  programId,
  type,
}: {
  programId: string;
  type?: ProgramType;
}) => {
  const [ref, inView] = useInView();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('programs');
  const { loading, count, error } =
    useAppSelector(state => state.locations.counts[programId], shallowEqual) ||
    {};

  useEffect(() => {
    if (count === undefined && inView && !loading && !error) {
      dispatch(getLocationsCount(programId));
    }
  }, [dispatch, inView, count, programId, loading, error]);

  return (
    <span data-testid="location-count" ref={ref}>
      {getCountDisplay(t, count, type)}
    </span>
  );
};

export default LocationsCount;
