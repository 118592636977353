import styled from 'styled-components';
import { Input, Tag } from 'antd';

import { colors } from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledInput = styled(Input)`
  min-width: 50px;
  width: 100%;
  flex: 1;

  border: none;
  outline: none;
  padding: 0;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const StyledTag = styled(Tag)`
  background: ${colors.yellow2};
  color: ${colors.darkYellow};
  border-radius: 4px;
  padding: 2px 8px;
  margin-bottom: 2px;

  .ant-tag-close-icon {
    color: ${colors.darkYellow};
  }
`;
