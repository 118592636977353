import { Row, Col, Slider, InputNumber } from 'antd';
import React from 'react';

export type PercentageSliderProps = {
  min: number;
  max: number;
  value: number;
  step: number;
  name?: string;
  onChange: (value: number) => void;
  onBlur?: () => void;
};
export default function PercentageSliderWithInput({
  min,
  max,
  value,
  step,
  onChange,
  onBlur,
  name,
}: PercentageSliderProps) {
  const formatter = (rawValue?: number | undefined) => `${rawValue}%`;

  return (
    <Row>
      <Col span={16}>
        <Slider
          tipFormatter={formatter}
          min={min}
          max={max}
          onChange={onChange}
          onAfterChange={onBlur}
          value={typeof value === 'number' ? value : 0}
          step={step}
        />
      </Col>
      <Col span={6}>
        <InputNumber
          min={min}
          max={max}
          style={{ margin: '0 16px' }}
          step={step}
          value={value}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}
