import React, { useState } from 'react';
import { Row } from 'antd';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { Brand } from '../../../../store/brands/brands-model';
import { CreateUpdateOfferFormModel } from '../../types';
import CardActivation from './components/CardActivation';
import BrandSelect from './components/BrandSelect';
import OfferName from './components/OfferName';
import CountrySelect from './components/CountrySelect';
import DiscountInput from './components/DiscountInput';
import ReturnDaysInput from './components/ReturnDaysInput';
import ScheduleCheckboxGroup from './components/ScheduleCheckboxGroup';
import StartEndDateTime from './components/StartEndDateTime';
import SchemesCheckboxGroup from './components/SchemesCheckboxGroup';
import TermsTextarea from './components/TermsTextarea';
import SelfFundedCheckbox from './components/SelfFundedCheckbox';
import ReportingProvider from './components/ReportingProvider';
import MetadataFields from './components/MetadataFields';
import ContentProvider from '../../components/ContentProvider';
import CustomerRecency from './components/CustomerRecency';
import useIsSignedIn from '../../../../hooks/use-is-signed-in';
import GrossComissionInput from './components/GrossComissionInput';
import ApprovalCheckbox from './components/ApprovalCheckbox';
import MaxPaymentTimelineInput from './components/MaxPaymentTimelineInput';
import OfferChannelsSelect from './components/OfferChannelsSelect';
import DescriptionTextarea from './components/DescriptionTextarea';
import MaxReward from './components/MaxReward';
import MinSpend from './components/MinSpend';
import FeeCustomisationInput from './components/FeeCustomisation';

export interface OfferFormProps {
  formModel: CreateUpdateOfferFormModel | null;
  isUpdating: boolean;
  isMarketplaceOffer: boolean;
  visible?: boolean;
  isOaas?: boolean;
}

export default function OfferForm({
  formModel,
  isUpdating,
  isMarketplaceOffer,
  visible,
  isOaas = false,
}: OfferFormProps) {
  const { contentProviderInfo } = useAppSelector(
    ({ account }) => account,
    shallowEqual,
  );
  const { isModerator } = useIsSignedIn();

  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(
    undefined,
  );

  const isContentProvider = !!contentProviderInfo;
  const isContentProviderUpdating = isContentProvider && isUpdating;
  const isPublisher = !isContentProvider && !isModerator;
  const shouldDisplayReportingProvider = isPublisher ? !isUpdating : true;

  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <BrandSelect
        isUpdating={isUpdating}
        setSelectedBrand={setSelectedBrand}
      />

      <OfferName disabled={isContentProviderUpdating || isMarketplaceOffer} />

      <CountrySelect isUpdating={isUpdating} />

      {!isContentProvider && (
        <DiscountInput
          isUpdating={isUpdating}
          isMarketplaceOffer={isMarketplaceOffer}
        />
      )}

      {isPublisher && isOaas && <FeeCustomisationInput />}

      <ContentProvider>
        <GrossComissionInput isUpdating={isUpdating} />
        {isOaas && <FeeCustomisationInput />}
        <ApprovalCheckbox disabled={isUpdating} />
        <MaxPaymentTimelineInput isUpdating={isUpdating} />
        <OfferChannelsSelect disabled={isUpdating} />
        <DescriptionTextarea type="short" disabled={isUpdating} />
        <DescriptionTextarea type="long" disabled={isUpdating} />
      </ContentProvider>

      <ReturnDaysInput formModel={formModel} disabled={isMarketplaceOffer} />

      <ScheduleCheckboxGroup disabled={isMarketplaceOffer} />

      <Row gutter={16}>
        <MinSpend disabled={isMarketplaceOffer} />
        <MaxReward disabled={isMarketplaceOffer} />
      </Row>

      <StartEndDateTime
        contentProviderInfo={contentProviderInfo}
        isMarketplaceOffer={isMarketplaceOffer}
      />

      <CardActivation isUpdating={isUpdating} />

      <SchemesCheckboxGroup
        disabled={isContentProviderUpdating || isMarketplaceOffer}
      />

      <TermsTextarea
        disabled={isContentProviderUpdating || isMarketplaceOffer}
      />

      <SelfFundedCheckbox
        isUpdating={isUpdating}
        isMarketplaceOffer={isMarketplaceOffer}
        selectedBrand={selectedBrand}
      />

      <ContentProvider>
        <CustomerRecency disabled={isUpdating} />
      </ContentProvider>

      {shouldDisplayReportingProvider && (
        <ReportingProvider
          formModel={formModel}
          disabled={isMarketplaceOffer}
        />
      )}

      <MetadataFields storedMetadata={formModel?.metadata} />
    </div>
  );
}
