import { useRef, Ref } from 'react';
import { RegisterOptions } from 'react-hook-form';

const required = { required: true };

type OfferFormField =
  | 'brandId'
  | 'offerName'
  | 'country'
  | 'discountType'
  | 'discountAmount'
  | 'minSpend'
  | 'maxReward'
  | 'qualifiedTransactionsLimit'
  | 'returnDays'
  | 'startDate'
  | 'startTime'
  | 'endDate'
  | 'endTime'
  | 'schedule'
  | 'schemes'
  | 'publisherFee'
  | 'fidelFee'
  | 'maxPaymentTimeline'
  | 'grossCommission'
  | 'customerRecency'
  | 'terms'
  | 'supplierInfo'
  | 'metadata';

type DefaultFields = Exclude<
  OfferFormField,
  'startTime' | 'endDate' | 'endTime'
>;

const hasAtLeastOneValue = (value: unknown[]) => value?.length > 0;

const createUpdateOfferRules: Record<DefaultFields, RegisterOptions> = {
  brandId: required,
  offerName: { ...required, minLength: 2, maxLength: 50 },
  country: required,
  discountType: required,
  discountAmount: { ...required, min: 0 },
  minSpend: { min: 0 },
  maxReward: { min: 0 },
  qualifiedTransactionsLimit: { min: 1 },
  returnDays: { min: 7, max: 150, ...required },
  fidelFee: { min: 1, max: 100 },
  publisherFee: { min: 1, max: 100 },
  startDate: required,
  schedule: { validate: { hasAtLeastOneValue } },
  schemes: { validate: { hasAtLeastOneValue } },
  maxPaymentTimeline: { min: 0 },
  grossCommission: { min: 0 },
  customerRecency: required,
  terms: { minLength: 4, maxLength: 300 },
  supplierInfo: required,
  metadata: required,
};

export function useOfferFormRef(): Record<string, Ref<HTMLElement>> {
  return {
    discountAmount: useRef<HTMLElement>(null),
  };
}

export default createUpdateOfferRules;
