import React from 'react';
import { useFormContext } from 'react-hook-form';
import Form from '../../../../../components/form';
import TaggedInput from '../../../../../components/tag/tagged-input';

const MidsInput = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.ItemController
      errors={errors}
      controller={{
        name: 'mids',
        render: ({ field: { ref, value, ...rest } }) => (
          <TaggedInput isDisabled={isDisabled} value={value || []} {...rest} />
        ),
        rules: {
          required: false,
          validate: {
            duplicateMids: values =>
              values &&
              values.length &&
              values.find(
                (item: string, index: number) => values.indexOf(item) !== index,
              ),
          },
        },
        control,
      }}
    />
  );
};

export default MidsInput;
