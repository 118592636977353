import { Input } from 'antd';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Offer } from '../../../store/offers/offers-model';
import { FormButton } from '../../../components/buttons';
import DrawerForm from '../../../components/drawer-form';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import Form from '../../../components/form';
import { RemoveKeyValuePair } from '../../../components/form/styled';
import { AntdSelectType, Select } from '../../../components/select';
import useFocusField from '../../../hooks/use-focus-field';
import useSelectedProgram from '../../../hooks/use-selected-program';
import useTransactionStream from '../../../hooks/use-transaction-stream';
import { getAllOffers } from '../../../store/offers';
import { getPrograms } from '../../../store/programs/programs-actions';
import regex from '../../../utils/regex';
import isHeaderAllowed from '../blacklisted-headers';
import { webhookEvents, WebhookEvent } from '../events';
import { useCreateWebhook } from '../api/useCreateWebhook';
import { useUpdateWebhook } from '../api/useUpdateWebhook';
import {
  CustomHeadersTitle,
  CustomHeadersWrapper,
  Equals,
} from '../styled/create-update-webhook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  HeadersKeys,
  WebhookHeaders,
  WebhookHeadersForm,
  WebhookPayload,
} from '../api/types';

interface CreateUpdateWebhookProps extends DrawerVisibleProps {
  webhook?: {
    id: string;
    event: WebhookEvent;
    programId: {
      id: string;
      error?: string;
    };
    url: string;
    headers?: WebhookHeaders;
    offerId?: string;
  };
  onClose: () => void;
}

function fromHeadersToForm(headers: WebhookHeaders) {
  return Object.entries(headers).map(([key, value]) => ({ key, value }));
}

function fromFormToHeaders(formHeaders: WebhookHeadersForm[]) {
  return formHeaders.reduce(
    (result, { key, value }) => ({
      ...result,
      [key]: value,
    }),
    {} as WebhookHeaders,
  );
}

const CreateUpdateWebhook = ({
  onClose,
  webhook,
  visible,
  afterVisibleChange,
}: CreateUpdateWebhookProps) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'webhooks.createUpdate',
  });
  const dispatch = useAppDispatch();
  const { hasProgramTransactionStream } = useTransactionStream();

  const { selectedProgram } = useSelectedProgram();
  const programId = selectedProgram?.id;

  const offers = useAppSelector(state => state.offers.offers.all);

  const isDistroOnly = selectedProgram?.type === 'transaction-distro-only';

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      event: webhook?.event ?? '',
      url: webhook?.url ?? '',
      programId: webhook?.programId,
      headers: webhook?.headers ? fromHeadersToForm(webhook.headers) : [],
      offerId: webhook?.offerId,
    },
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  });

  const createWebhook = useCreateWebhook(programId);
  const updateWebhook = useUpdateWebhook(programId);

  const selectRef = useFocusField<AntdSelectType>();

  useEffect(() => {
    dispatch(getPrograms());

    if (!hasProgramTransactionStream) {
      dispatch(getAllOffers());
    }
  }, [dispatch, hasProgramTransactionStream]);

  function onSubmit({
    event,
    url,
    offerId,
    headers,
  }: {
    event: string;
    url: string;
    offerId: string | undefined;
    headers: WebhookHeadersForm[];
  }) {
    const webhookToSubmit: WebhookPayload = { event, url };

    if (webhookToSubmit && offerSelectorVisible && offerId)
      webhookToSubmit.offerId = offerId;

    if (headers?.length) {
      webhookToSubmit.headers = fromFormToHeaders(headers);
    }

    if (webhook) {
      updateWebhook.mutate({
        id: webhook.id,
        webhook: webhookToSubmit,
      });
    } else {
      createWebhook.mutate(webhookToSubmit);
    }
  }

  const isUpdate = !!webhook;

  const isProcessing = isUpdate
    ? updateWebhook.isLoading
    : createWebhook.isLoading;

  const webhookFilteringByProduct = (event: WebhookEvent) => {
    if (isDistroOnly) {
      return !(
        event.includes('card') ||
        event.includes('location') ||
        event.includes('brand') ||
        event.includes('program')
      );
    }
    if (hasProgramTransactionStream) {
      return !(
        event.includes('location') ||
        event.includes('brand') ||
        event.includes('qualified') ||
        event.includes('offer') ||
        event.includes('program')
      );
    }
    return !(
      event.includes('card.verification') || event.includes('card.data.sharing')
    );
  };
  const events = webhookEvents.filter(webhookFilteringByProduct);

  const offerSelectorVisible =
    offers &&
    ['transaction.auth.qualified', 'transaction.clearing.qualified'].includes(
      watch('event'),
    );

  return (
    <DrawerForm
      title={isUpdate ? t('update') : t('create')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      processing={isProcessing}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
    >
      <Form.ItemController
        label={t('event.label')}
        errors={errors}
        controller={{
          name: 'event',
          render: ({ field }) => (
            <Select
              {...field}
              data-name="event"
              placeholder={t('event.placeholder')}
              ref={selectRef}
            >
              {events.map(event => (
                <Select.Option key={event} value={event}>
                  {event}
                </Select.Option>
              ))}
            </Select>
          ),
          control,
          rules: {
            required: true,
          },
        }}
      />
      {offerSelectorVisible && (
        <Form.ItemController
          label={t('offer.label')}
          controller={{
            name: 'offerId',
            render: ({ field }) => (
              <Select
                {...field}
                data-name="offer"
                placeholder={t('offer.placeholder')}
              >
                {(offers || []).map(
                  ({ id, name }: Offer) =>
                    id && (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ),
                )}
              </Select>
            ),
            control,
          }}
        />
      )}
      <Form.ItemController
        label={t('url.label')}
        errors={errors}
        controller={{
          name: 'url',
          render: ({ field }) => (
            <Input {...field} placeholder={t('url.placeholder')} />
          ),
          rules: {
            required: true,
            validate: { invalidUrl: value => regex.httpsUrl.test(value) },
          },
          control,
        }}
      />
      <CustomHeadersTitle>Custom headers</CustomHeadersTitle>
      {fields.map((field, i) => {
        const getError = (name: HeadersKeys) => ({
          [`headers.${i}.${name}`]: errors.headers?.[i]?.[name],
        });

        return (
          <CustomHeadersWrapper key={field.id}>
            <Form.ItemController
              label={t('headers.key')}
              errors={getError('key')}
              controller={{
                name: `headers.${i}.key`,
                render: ({ field: props }) => (
                  <Input
                    {...props}
                    placeholder={t('headers.key')}
                    defaultValue={field.key}
                    disabled={isProcessing}
                  />
                ),
                rules: {
                  required: true,
                  minLength: 1,
                  maxLength: 64,
                  validate: {
                    blacklistedHeader: isHeaderAllowed,
                    alphanumericDashes: value => /^[a-zA-Z0-9-_]+$/.test(value),
                  },
                },
                control,
              }}
            />
            <Equals />
            <Form.ItemController
              label={t('headers.value')}
              errors={getError('value')}
              controller={{
                name: `headers.${i}.value`,
                render: ({ field: props }) => (
                  <Input
                    {...props}
                    placeholder={t('headers.value')}
                    defaultValue={field.value}
                    disabled={isProcessing}
                  />
                ),
                rules: {
                  required: true,
                  minLength: 1,
                  maxLength: 1000,
                },
                control,
              }}
            />
            <RemoveKeyValuePair
              onClick={() => remove(i)}
              data-testid="remove"
            />
          </CustomHeadersWrapper>
        );
      })}
      {fields.length < 5 && (
        <FormButton size="small" block onClick={() => append({})}>
          {t('addHeader')}
        </FormButton>
      )}
    </DrawerForm>
  );
};

export default drawerTransition(CreateUpdateWebhook);
