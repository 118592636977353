import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../store/hooks';

function useTransactionDistroOnly() {
  const { products, isLive } = useAppSelector(
    state => ({
      products: state.account.details?.products,
      isLive: state.live,
    }),
    shallowEqual,
  );

  return {
    hasAccountTransactionDistroOnly:
      !!isLive && products?.transactionDistroOnly === true,
  };
}

export default useTransactionDistroOnly;
