import { FiltersProps } from '../../../components/filters';
import { FilterName } from '../../../components/filters/components/FilterDropdown';
import { OfferType } from '../../../store/offers/offers-model';
import { OfferHeading, columnsByTab, filtersByTab } from './config';

export function getTableContentByTab<T>(
  offerType: OfferType,
  object: Record<OfferHeading, T>,
  isContentProvider?: boolean,
): T[] {
  const columnsByTabByOfferType = columnsByTab[offerType];

  // remove the if condition once a publisher is also able to have different offer types per account
  if (!isContentProvider) {
    const columnsWithoutTransactionSource = columnsByTabByOfferType.filter(
      column => column !== 'transactionSource',
    );

    return columnsWithoutTransactionSource.map(record => object[record]);
  }

  return columnsByTabByOfferType.map(record => object[record]);
}

export function getFilters(
  tab: OfferType,
  isContentProvider = false,
): FiltersProps {
  const filtersConfig = { ...filtersByTab[tab] };

  let hiddenFilters: FilterName[] = ['countryCode', 'contentProviderId'];

  if (!isContentProvider) {
    hiddenFilters = ['transactionSource'];
  }

  return {
    defaultFilters: filtersConfig.defaultFilters.filter(
      name => !hiddenFilters.includes(name),
    ),
    moreFilters: filtersConfig.moreFilters?.filter(
      name => !hiddenFilters.includes(name),
    ),
  };
}
