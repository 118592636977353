import { Radio } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../../theme';
import Avatar from '../../../../components/avatar';

export const OfferTransactionSourceRadio = styled(Radio)<{ $active: boolean }>`
  display: flex;
  align-items: center;

  height: 180px;
  margin: 15px 0;
  padding: 15px;

  border-radius: 4px;
  border: 2px solid ${p => (p.$active ? colors.blue2 : colors.grey2)};

  .ant-radio {
    margin-right: 10px;
  }

  .ant-radio ~ span {
    width: 100%;
  }

  header {
    margin-bottom: 10px;
    font-size: 14px;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${colors.grey4};
  }

  ${Avatar} {
    float: right;
  }
`;
